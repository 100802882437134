@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '~@nebular/theme/styles/globals';
@import '~@nebular/auth/styles/all';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
};


// Mixins and Placeholders
%clearfix {
  &:after, &:before {
    content: '';
    display: block;
    width: 100%;
    clear: both;
  }
}

@mixin prefix($prop, $val) {
  @each $prefix in '-webkit-', '-moz-', '-ms-', '' {
    #{$prefix}#{$prop}: $val;
  }
}
// Timeline
// Timeline
$timeline-color: #ee4d4d;

#timeline {
  line-height: 1.5em;
  font-size: 14px;
  width: 90%;
  margin: 30px auto;
  position: relative;
  @include prefix(transition, all .4s ease);

  &,
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  &:before {
    content:"";
    width: 3px;
    height: 100%;
    background: $timeline-color;
    left: 50%;
    top: 0;
    position: absolute;
  }

  &:after {
    content: "";
    clear: both;
    display: table;
    width: 100%;
  }

  .timeline-item {
    margin-bottom: 50px;
    position: relative;
    @extend %clearfix;

    .timeline-icon {
      background: $timeline-color;
      width: 50px;
      height: 50px;
      position: absolute;
      top: 0;
      left: 50%;
      overflow: hidden;
      margin-left: -23px;
      @include prefix(border-radius, 50%);

      img {
        position: relative;
        transform: translate(50%, 50%);
      }
    }

    .timeline-content {

      background: #fff;
      padding: 20px;
      position: relative;
      @include prefix(box-shadow, 0 3px 0 rgba(0,0,0,0.1));
      @include prefix(border-radius, 5px);
      @include prefix(transition, all .3s ease);
      p{
        color: #000000;
        margin-bottom: 0px;
        margin-top: 15px;

      }

      .titulo {
        padding: 15px;
        background: $timeline-color;
        color: #fff;
        margin: -20px -20px 0 -20px;
        font-weight: 500;
        @include prefix(border-radius, 3px 3px 0 0);
      }

      &:before {
        content: '';
        position: absolute;
        top: 20px;
        right: -5px;
        width: 14px;
        height: 14px;
        background-color: $timeline-color;
        display: block;
        border-radius: 3px;
        transform: rotate(45deg);
      }

      &.right {
        float: right;

        &:before {
          left: -5px;
          right: inherit;
        }
      }
    }
  }
}
#timeline {
  margin: 30px;
  padding: 0;
  &:before {
    left: 0;
  }

  .timeline-item {
    .timeline-content {
      width: 100%;
      left: 45px;
      float: right;

      &:before,
      &.right:before {
        left: -5px;
        right: inherit;
      }
    }

    .timeline-icon {
      left: 0;
    }
  }
}


table{
  white-space: nowrap;
}
